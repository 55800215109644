import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'react-bootstrap';
import classnames from 'classnames';

const Section = ({className, children, gray}) => {
  const classes = classnames(
    {
      'py-5': true,
      'gray-background': gray,
    },
    className
  );

  return (
    <Container fluid className={classes} as="section">
      <Container>{children}</Container>
    </Container>
  );
};

Section.defaultProps = {
  gray: false,
};

Section.propTypes = {
  gray: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Section;
